<template>
  <v-card class="elevation-0">
    <div>
      <div class="row-title-btn">
        <div class="controls-actions d-flex justify-end">
          <v-btn @click="openDialog" icon class="ml-2">
            <v-icon size="24">{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="finalities"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height elevation-3"
      fixed-header
      disable-sort
      hide-default-footer
    >
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons @edit="openEdit(item)" @remove="openRemove(item)"></CrudButtons>
        </div>
      </template>
    </v-data-table>
    <WDialog width="600" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormFinalities
          v-if="dialog"
          :loading="loading"
          :finalityEdited="finalityEdited"
          @beforeUpdate="updateFinality"
          @beforeCreate="createFinality"
        ></FormFinalities>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import FormFinalities from '@/components/forms/FormFinalities.vue'
import useFinalities from '@/composables/useFinalities'
import WDialog from '@/components/dialogs/WDialog.vue'

export default {
  components: {
    CrudButtons,
    WDialog,
    FormFinalities,
  },
  setup() {
    const {
      dialog,
      finalityEdited,
      finalities,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createFinality,
      updateFinality,
    } = useFinalities()
    return {
      icons: {
        mdiPlus,
      },
      dialog,
      finalityEdited,
      finalities,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createFinality,
      updateFinality,
      headers: [
        { text: 'FINALIDAD', value: 'list' },
        { text: '', value: 'actions', align: 'right' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #00aca0;
  padding: 10px;
  margin: 10px;
}

.icon-service img {
  max-width: 50px;
  max-height: 50px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>