<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formFinality" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12">
            <label for="list">Finalidad</label>
          </v-col>

          <v-col cols="12" class="pb-0">
            <v-textarea
              id="list"
              v-model="finality.list"
              outlined
              dense
              rows="6"
              placeholder="Finalidad"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
export default {
  components: {
    FormTemplate,
  },
  props: {
    finalityEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      finality: this.finalityEdited ? { ...this.finalityEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formFinality.validate()
    },
    reset() {
      this.$refs.formFinality.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.finalityEdited) {
        let DTO = { ...this.finality }
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.finality }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #00aca0;
  padding: 20px;
}

.icon-service img {
  max-width: 80px;
  max-height: 80px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
