<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formAboutUsPg" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12">
            <h3>Portada</h3>
          </v-col>

          <v-col cols="12" md="2">
            <label for="title_cover_page">Título de Portada</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_cover_page"
              v-model="aboutUsPg.title_cover_page"
              outlined
              dense
              placeholder="Título de la Portada"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="img_cover_page">Imagen de Portada</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="img_cover_page"
              v-model="file_cover_page"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col cols="12">
            <div class="img-cover section">
              <img draggable="false" :src="url || loadImage(aboutUsPg.img_cover_page)" :alt="aboutUsPg.text" />
              <h1 class="cover-title">{{ aboutUsPg.title_cover_page }}</h1>
            </div>
          </v-col>

          <v-col cols="12">
            <h3>Acerca de Nosotros</h3>
          </v-col>

          <v-col cols="12" md="2">
            <label for="title_about">Título de Acerca de Nosotros</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_about"
              v-model="aboutUsPg.title_about"
              outlined
              dense
              placeholder="Título de Acerca de Nosotros"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="img_about">Imagen de Acerca de Nosotros</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="img_about"
              v-model="file_about"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileNewChange"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="2">
            <label for="description_about">Contenido de Acerca de Nosotros</label>
          </v-col>

          <v-col cols="12" md="10" class="pb-0">
            <v-textarea
              id="description_about"
              v-model="aboutUsPg.description_about"
              outlined
              dense
              placeholder="Cargo del Funcionario"
              rows="5"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <div class="section">
              <v-row>
                <v-col class="img-finalities" cols="12" md="6">
                  <img
                    class=""
                    draggable="false"
                    :src="urlNew || loadImage(aboutUsPg.img_about)"
                    :alt="aboutUsPg.text"
                  />
                </v-col>
                <v-col cols="12" md="6" class="content-about">
                  <div>
                    <h1 class="title-about">{{ aboutUsPg.title_about }}</h1>
                    <p class="text-about">{{ aboutUsPg.description_about }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12">
            <h3>Misión - Visión</h3>
          </v-col>

          <v-col cols="12" md="2">
            <label for="content_mission">Misión</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-textarea
              id="content_mission"
              v-model="aboutUsPg.content_mission"
              outlined
              dense
              placeholder="Misión"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="2">
            <label for="content_vision">Visión</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-textarea
              id="content_vision"
              v-model="aboutUsPg.content_vision"
              outlined
              dense
              placeholder="Visión"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <h3>Finalidad</h3>
          </v-col>

          <v-col cols="12" md="2">
            <label for="img_cover_page">Imagen al lado de las finalidades</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="img_cover_page"
              v-model="file_finality"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileOther"
            ></v-file-input>
          </v-col>

          <v-col cols="12">
            <div class="img-finalities section">
              <img draggable="false" :src="urlOther || loadImage(aboutUsPg.img_finality)" :alt="aboutUsPg.text" />
            </div>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>

          <v-col cols="12">
            <DataTableFinalities></DataTableFinalities>
          </v-col>

          <v-col cols="12">
            <h3>Valores</h3>
          </v-col>

          <v-col cols="12">
            <DataTableValues></DataTableValues>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import DataTableFinalities from '@/components/data-table/DataTableFinalities.vue'
import DataTableValues from '@/components/data-table/DataTableValues.vue'
import { loadAPIImage } from '@/utils/images'
export default {
  components: {
    FormTemplate,
    DataTableFinalities,
    DataTableValues,
  },
  props: {
    getAboutUsPg: Function,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      urlNew: '',
      urlOther: '',
      file_cover_page: null,
      file_about: null,
      file_finality: null,
      aboutUsPg: {},
    }
  },
  async created() {
    this.aboutUsPg = await this.getAboutUsPg()
  },
  methods: {
    validateForm() {
      return this.$refs.formAboutUsPg.validate()
    },
    reset() {
      this.$refs.formAboutUsPg.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      let DTO = { ...this.aboutUsPg }

      if (this.file_cover_page) {
        DTO = {
          ...DTO,
          file_cover_page: this.file_cover_page,
        }
      }

      if (this.file_about) {
        DTO = {
          ...DTO,
          file_about: this.file_about,
        }
      }

      if (this.file_finality) {
        DTO = {
          ...DTO,
          file_finality: this.file_finality,
        }
      }
      // // console.log('actualizar', DTO)
      this.$emit('beforeUpdate', DTO)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    onFileNewChange(e) {
      if (!e) return
      this.urlNew = URL.createObjectURL(e)
    },
    onFileOther(e) {
      if (!e) return
      this.urlOther = URL.createObjectURL(e)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-cover {
  height: 30vh;
  max-height: 400px !important;
  min-height: 250px !important;
  position: relative;
}

.img-cover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.img-cover .cover-title {
  width: 100%;
  position: absolute;
  bottom: 10%;
  color: white !important;
  font-size: 25px;
  margin-left: 10%;
  text-transform: uppercase;
}

.img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-finalities {
  display: flex;
}

.img-finalities img {
  max-width: 100%;
  max-height: 500px;
  margin: auto;
}

.title-about {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
}

.content-about {
  display: flex;
  align-items: center;
}

.text-about {
  white-space: pre-wrap;
  font-size: 14px;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
</style>
