import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useFinalities = () => {

    // base data
    const dialog = ref(false);
    const finalityEdited = ref(null);
    const finalities = ref([]);
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        finalityEdited.value ? "Editar Finalidad" : "Agregar Finalidad"
    );
    const subtitleDialog = computed(() =>
        finalityEdited.value ?
        "Rellene los campos correctamente para modificar los datos de la finalidad" :
        "Rellene los campos correctamente para validar los datos de la finalidad"
    );

    const closeDialog = () => {
        dialog.value = false;
        finalityEdited.value = null;
    }

    // methods
    const getFinalitiesList = async() => {
        const { data, status } = await store.dispatch("fetchFinalities")
        if (status != 200) return;
        finalities.value = data;
    }

    const createFinality = async(finality) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createFinality", finality)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getFinalitiesList();
        closeDialog();
    }

    const updateFinality = async(finality) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateFinality", finality)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getFinalitiesList();
        closeDialog();
    }

    const removeFinality = async(finality) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeFinality", finality)
        if (status != 200 && status != 201 && status != 204) return;
        await getFinalitiesList();
    }

    getFinalitiesList();

    return {
        dialog,
        finalityEdited,
        finalities,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        openDialog() {
            dialog.value = true;
        },
        openEdit(finality) {
            dialog.value = true;
            finalityEdited.value = {...finality };
        },
        openRemove: removeFinality,
        closeDialog,
        createFinality,
        updateFinality
    };
}

export default useFinalities;
