import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useValues = () => {

    // base data
    const dialog = ref(false);
    const valueEdited = ref(null);
    const values = ref([]);
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        valueEdited.value ? "Editar Valor" : "Agregar Valor"
    );
    const subtitleDialog = computed(() =>
        valueEdited.value ?
            "Rellene los campos correctamente para modificar los datos del Valor" :
            "Rellene los campos correctamente para validar los datos del nuevo Valor"
    );

    const closeDialog = () => {
        dialog.value = false;
        valueEdited.value = null;
    }

    // methods
    const getValuesList = async () => {
        const { data, status } = await store.dispatch("fetchValues")
        if (status != 200) return;
        values.value = data;
    }

    const createValue = async (value) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createValue", value)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getValuesList();
        closeDialog();
    }

    const updateValue = async (value) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateValue", value)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getValuesList();
        closeDialog();
    }

    const removeValue = async (value) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeValue", value)
        if (status != 200 && status != 201 && status != 204) return;
        await getValuesList();
    }

    getValuesList();

    return {
        dialog,
        valueEdited,
        values,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        openDialog() {
            dialog.value = true;
        },
        openEdit(value) {
            dialog.value = true;
            valueEdited.value = { ...value };
        },
        openRemove: removeValue,
        closeDialog,
        createValue,
        updateValue
    };
}

export default useValues;
