<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formValue" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="icon">Icono</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="icon"
              v-model="image"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col v-if="url || valueEdited" cols="12">
            <div class="icon-service center-sub-items">
              <img draggable="false" :src="url || loadImage(value.icon)" :alt="value.moral_values" />
            </div>
          </v-col>

          <v-col cols="12" md="3">
            <label for="moral_values">Valor</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="moral_values"
              v-model="value.moral_values"
              outlined
              dense
              placeholder="Valor"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="description">Descripción</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-textarea
              id="description"
              v-model="value.description"
              outlined
              dense
              placeholder="Descripción del valor"
              rows="6"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    valueEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      value: this.valueEdited ? { ...this.valueEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formValue.validate()
    },
    reset() {
      this.$refs.formValue.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.valueEdited) {
        let DTO = { ...this.value }
        if (this.image) {
          DTO = {
            ...DTO,
            file: this.image,
          }
        }
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.value, icon: this.image }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: white;
  padding: 20px;
}

.icon-service img {
  max-width: 80px;
  max-height: 80px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
