<template>
  <v-card>
    <AboutUsPgHeader></AboutUsPgHeader>
    <section class="form-full-width">
      <FormAboutUsPg :loading="loading" :getAboutUsPg="getAboutUsPg" @beforeUpdate="updateAboutUsPg"></FormAboutUsPg>
    </section>
  </v-card>
</template>

<script>
import FormAboutUsPg from '@/components/forms/FormAboutUsPg.vue'
import AboutUsPgHeader from './AboutUsPgHeader.vue'
import useAboutUsPg from '@/composables/useAboutUsPg'

export default {
  components: {
    FormAboutUsPg,
    AboutUsPgHeader,
  },
  setup() {
    // Composition API
    const {
      aboutUsPg,
      loading,
      // methods
      getAboutUsPg,
      updateAboutUsPg,
    } = useAboutUsPg()

    return {
      aboutUsPg,
      loading,
      // methods
      getAboutUsPg,
      updateAboutUsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
